<script setup>
import { computed, watch } from 'vue';
import FlashMessages from '@/Layouts/Partials/FlashMessages.vue';
import { useI18n } from 'vue-i18n';
import { useCurrentLocale } from '@/Composables/useCurrentLocale';

const currentLocale = computed(() => useCurrentLocale());

const { locale } = useI18n();

locale.value = currentLocale.value?.locale;

watch(currentLocale, (value) => {
    locale.value = value?.locale;
});
</script>

<template>
    <slot />
    <FlashMessages />
</template>
