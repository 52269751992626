export default class Locale {
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    getLocalizedUrl(url) {
        return `/${this.locale}${url}`;
    }

    getLocalizedNamedRoute(name, parameters = {}) {
        if (this.isDefault) {
            return route(name, parameters, false);
        }

        return this.getLocalizedUrl(route(name, parameters, false));
    }
}
